@use '@angular/material' as mat;

@import "shared/variables";
@import "shared/loader";

@include loader-theme($pd-theme-new);

table.grid-table {
  width: 100%;
  table-layout: fixed;

  th:first-child,
  td:first-child {
    padding: 0 !important;
  }

  &.no-actions {
    th:first-child,
    td:first-child {
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 0.5rem !important;
    }
  }

  th:not(:first-child),
  td:not(:first-child) {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 0.5rem;
  }

  tr.bold-mat-footer-row {
    font-weight: bold;
  }

  .mat-button {
    text-align: left;
  }
}
  
.remove-column {
  display: none;
}
  
.filter-header {
  display: flex;
  align-items: center;
}

.mat-sort-header-content{
    padding-right:3px;
}
  
app-date-time-filter, app-string-filter, app-number-filter, app-bool-filter, app-guid-filter, app-guid-list-filter {
  opacity: 0.54;
  transition: opacity 0.2s ease-in-out;
}
  
.filter-header:not(:hover) app-date-time-filter:not(.header-filter-active),
.filter-header:not(:hover) app-string-filter:not(.header-filter-active),
.filter-header:not(:hover) app-number-filter:not(.header-filter-active),
.filter-header:not(:hover) app-bool-filter:not(.header-filter-active),
.filter-header:not(:hover) app-enum-filter:not(.header-filter-active),
.filter-header:not(:hover) app-guid-filter:not(.header-filter-active),
.filter-header:not(:hover) app-guid-list-filter:not(.header-filter-active)  {
  opacity: 0;
}

.header-filter-active {
  opacity: 1 !important;
  color: mat.get-color-from-palette($pd-primary);
}
  
.mat-column-actions {
  width: 50px;
}
