// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@import "shared/variables";
@import "shared/loader";
@import "grid-table";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($pd-typography);`

@include mat.all-component-themes($pd-theme-new);
@include mat.all-component-typographies($pd-theme-new);
@include loader-theme($pd-theme-new);

//@include mat.all-legacy-component-typographies($pd-typography);
//@include mat.legacy-core();

//@include mat.all-legacy-component-themes($pd-theme);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$core-app-primary: mat.define-palette(mat.$indigo-palette);
//$core-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$core-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
/*$core-app-theme: mat.define-light-theme((
  color: (
    primary: $core-app-primary,
    accent: $core-app-accent,
    warn: $core-app-warn,
  )
));*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($core-app-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

// Add syncfusion scheduler view
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';


/*.mat-step-icon {
  border: 1px solid lightgray !important;
  color: gray !important;
  background-color: white !important;
}

.mat-step-icon-selected {
  color: $primary-color !important;
  font-weight: 600 !important;
  border: 2px solid $primary-color !important;
  background-color: #F2F5F5 !important;
}*/

body {
  margin: 0;
  font-family: Brandon, sans-serif;
}

@font-face {
  font-family: "Brandon";
  src: url("../assets/fonts/Brandon_reg.otf");
}

.flex-row-center {
  display: flex;
  align-items: center;
}

.custom-mat-hint {
  text-align: right;
  font-size: 75%;
  display: block;
}

.custom-stepper-buttons {
  float: right;
  margin-left: 24px;
}

.error-text {
  color: #f44336;
}

.error-background {
  background-color: #f44336;
}

.success-text {
  color: #28a745 !important;
}

.success-background {
  background-color: #28a745 !important;
}

.success-background:disabled {
  background-color: var(--mdc-protected-button-disabled-container-color) !important;
  color: var(--mdc-protected-button-disabled-label-text-color) !important;
}

button.grid-action-button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

// https://github.com/angular/components/issues/15657
.mat-cell,
.mat-cell button,
.mat-footer-cell,
.mat-header-cell,
.mat-paginator {
  font-size: 14px !important;
}

.mat-mdc-tooltip {
  //font-size: 16px !important;
  word-break: normal !important;
  white-space: pre-line !important;
}

.mat-checkbox {
  margin-right: 1em;
}

.mat-dialog-content-scrollbar-fix {
  &:after {
    content: " ";
    display: block;
    height: 10px;
  }
}

button.mat-basic {
  color: #25616b !important;
  // background-color: inherit !important;
  // box-shadow: none !important;
  // border: 1px solid #25616b !important;
}

dt {
  font-weight: 500;
}

// stlye for the dialog to have actions on one line with content
mat-dialog-actions {
  padding: 8px 24px 8px 24px !important;
}

@media (max-width: 600px) {
  .custom-stepper-buttons {
    margin: 0;
    float: unset;
  }
}

.hidden {
  display: none;
}

/******************** Begin Syncfusion Scheduler customization **************************/

// hide in editor virew the timezone and whole day option
.e-schedule-dialog .e-numeric.e-control-wrapper.e-input-group .e-input-group-icon {
  pointer-events: none;
}

.e-schedule-dialog .e-all-day-time-zone-row {
  display: none; // To disable Allday and Timezone options
}

.e-schedule-dialog .e-recurrenceeditor {
  display: none; // To disable recurrence option
}

.custom-schedule {
  $cell-height: 40px;

  colgroup {
    col {
      width: 45px !important;
    }
  }
  
  .e-appointment-wrapper {
    top: -2px; // Equalize additional 2px added to top by syncfusion

    .e-appointment {
      border: none !important;
      border-radius: 0 !important;
      background: none !important;
      height: $cell-height !important;
      padding: 1px;
    }
  }

  .e-work-cells {
    border-bottom-width: 1px !important;
    height: $cell-height !important;
  }

  .e-schedule-table .e-resource-column-table {
    td {
      height: $cell-height !important;
    }
  }
}

.white-icon {
  color: white !important;
}

/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon svg {
  fill: white !important;
}

.mat-form-field-button {
  &.mat-icon-button {
    margin-top: 0.7em;
  }

  &.mat-raised-button {
    margin-top: 0.7em;
  }
}

.mat-mdc-form-field-error-wrapper {
  position: relative !important;
  top: -15px !important;
} 

/******************** End Syncfusion Scheduler customization ***************************/
